import React from 'react';
import Header from './Header';

class Interview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showInterviewSession: false
        }
    }

    handleSubmit = (event) => {
        let search = window.location.search;
        let params = new URLSearchParams(search);
        const customerOrderProductId = params.get('t');
        event.preventDefault();
        const subjectId = this.props.subjectId;
        //const apiUrl = this.props.apiUrl;
        
        fetch(this.props.apiUrl + 'ExternalVendorSubject/Interview/customer/subjectSession/url',
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + this.props.accessToken
                },
                body: JSON.stringify({ 'input': { 'subjectId': subjectId, 'customerOrderProductId': customerOrderProductId } }),
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else {
                    return response.text()
                }
            })
            .then(responseData => {
                if (responseData && responseData.result) {
                    this.setState({ interviewUrl: responseData.result.url, showInterviewSession: true });
                } else {
                }
            })
            .catch((error) => {
            //    console.error(error);
            });
    }
    render() {
        if (!this.state.showInterviewSession) {
            return (
                <div>
                    <Header />
                    <div className="row">
                        <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                        <div className="col-md-8">
                            After you press Start Session, you will be automatically directed to one of Clarigent's virtual agents to complete the Clairity screener.<br />
                            <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                            Once you have finished the screener, please check your email for instructions on how to immediately connect via video or phone to a mental health professional to discuss your confidential results. Follow-up consultations typically last 45-60 minutes.
                            <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                            Click on the following link to read our consent to participate in the interview:  <a href="https://www.clarigenthealth.com/consent-dtc" target="_blank" rel="noopener noreferrer">Clairity Interview Consent</a>
                            <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                            If you consent to the interview, please click on the Start Session button.  Otherwise, you can close your browser.
                        </div>
                        <div className="row" style={{ marginTop: 4 + 'em' }}></div>
                        <form onSubmit={this.handleSubmit}>
                            <div className="col-4 text-start">
                                <button type='submit' className="btn btn-md btn-info text-white"><strong>Start Session</strong></button>
                            </div>
                        </form>
                        <div className="row" style={{ marginTop: 4 + 'em' }}></div>
                    </div>
                </div>
            );
        } else {
            return (
                    <iframe id="interviewIframe" title="interviewIframe" src={this.state.interviewUrl} width="100%" height="1000px" style={{ border: 'none', scroll: 'no'}} allow="camera;microphone"></iframe>
            );
        }
    }
}
export default Interview;