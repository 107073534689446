import React from 'react';
//import React, { useEffect, useState } from 'react';
//import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Header from './Header';
class Home extends React.Component {
    render() {
        var msg = "Welcome to Clarigent Registration.";
        var devMsg = "";
        if(process.env.NODE_ENV === "development") {
            //devMsg = "You are running this application in " + process.env.NODE_ENV + " mode.";
        }

        return (
            <div>
                <Header />
                <div className="row" style={{ marginLeft: 20 + 'em' }}>
                    <div className="row" style={{ marginTop: 2 + 'em' }}></div>
                    <div className="col-md-8">{ msg }
                        <div className="row" style={{ marginTop: 2 + 'em' }}></div>
                    <b>{devMsg} </b>
                    </div>
                 </div>
            </div>
        );
    }
}

export default Home;