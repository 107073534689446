import React from 'react';
import Interview from './Interview';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Header';

class Registration extends React.Component {
    constructor(props) {
        super(props);

        const minAge = this.props.minAge;
        const maxAge = this.props.maxAge;
        this.state = {
            showRegistrationForm: true,
            showInterviewSession: false,
            selectedDate: null,
            minDate: new Date(new Date().setFullYear(new Date().getFullYear() - maxAge)),
            maxDate: new Date(new Date().setFullYear(new Date().getFullYear() - minAge))
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    handleChange(date) {
        this.setState({
            selectedDate: date
        })
    }

    handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);

        const rawPhoneNumber = data.get('phoneNumber');
        const phoneNumber = rawPhoneNumber.replace(/[^+\d]+/g, "");

        fetch(this.props.apiUrl + 'subject/register',
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + this.props.accessToken,
                },
                body: JSON.stringify({ "input": { 'emailAddress': data.get('emailAddress'), 'firstName': data.get('firstName'), 'lastName': data.get('lastName'), 'birthDate': data.get('birthDate'), 'gender': data.get('gender'), 'phoneNumber': phoneNumber, 'customerOrderProductId': data.get('customerOrderProductId') } }),
            })
            .then(response => {
                this.setState({ accessToken: this.props.accessToken, apiUrl: this.props.apiUrl })
                if (response.status === 200) {
                    return response.json()
                } else {
                    return response.text()
                }
            })
            .then(responseData => {
                if (responseData) {
                    this.setState({ showInterviewSession: true, subjectId: responseData.result.id, showRegistrationForm: false });
                } else {
                }
            })
            .catch((error) => {
                //console.error(error);
            });
    }
    render() {
        const { subjectId, accessToken, apiUrl } = this.state;

        if (this.state.showRegistrationForm && this.props.customerOrderProductId != null) {

            return (
                <div>
                <Header />
                <div className="row">
                    <div className="row" style={{ marginTop: 2 + 'em' }}></div>
                    <h2 className="text-left">Registration</h2>
                    <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                        <span>Please complete the registration information below. It enables the mental health professional who will do your counseling session to see your Clairity screener results.</span>
                        <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                    <form onSubmit={this.handleSubmit}>
                        <div className="col-lg-2 text-start">
                            <label htmlFor="emailAddress" className="form-label"><strong>Email Address</strong></label>
                            <input type="email" className="form-control" name="emailAddress" id="emailAddress" defaultValue={this.props.customerEmailAddress} required />
                        </div>
                        <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                        <div className="col-md-2 text-start">
                            <label htmlFor="firstName" className="form-label"><strong>First Name</strong></label>
                            <input type="text" className="form-control" name="firstName" id="firstName" required />
                        </div>
                        <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                        <div className="col-md-2 text-start">
                            <label htmlFor="lastName" className="form-label"><strong>Last Name</strong></label>
                            <input type="text" className="form-control" name="lastName" id="lastName" required />
                        </div>
                        <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                        <div className="col-md-2 text-start">
                            <label htmlFor="birthDate" className="form-label"><strong>Date of Birth</strong></label><br />
                            <DatePicker
                                className="form-control"
                                placeholderText="MM/DD/YYYY"
                                onChange={this.handleChange}
                                selected={this.state.selectedDate}
                                name="birthDate"
                                id="birthDate"
                                required
                                dateFormat="MM/dd/yyyy"
                                minDate={this.state.minDate}
                                maxDate={this.state.maxDate}
                                showMonthYearDropdown
                            />
                        </div>
                        <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                        <div className="col-md-1 text-start">
                            <label htmlFor="gender" className="form-label"><strong>Gender</strong></label>
                            <select className="form-control" name="gender" id="gender" required>
                                <option value="" defaultValue>Select</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                                <option value="Unspecified">Unspecified</option>
                            </select>
                        </div>
                        <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                        <div className="col-md-2 text-start">
                            <label htmlFor="phoneNumber" className="form-label"><strong>Phone Number</strong></label>
                            <input type="Telephone" className="form-control" name="phoneNumber" id="phoneNumber" required />
                        </div>
                        <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                        <div className="col-md-2 text-start">
                            <input type="hidden" className="form-control" name="customerOrderProductId" id="customerOrderProductId" defaultValue={this.props.customerOrderProductId} required />
                        </div>
                        <button type='submit' className="btn btn-md btn-info text-white"><strong>Submit</strong></button>
                    </form>
                    </div>
                </div>
            );
        }
        else if (this.state.showInterviewSession) {
            return (
                <div>
                    <Interview subjectId={subjectId} accessToken={accessToken} apiUrl={apiUrl} />
                </div>
            );
        } else {
            return (
                <div className="mb-3 h-100 d-flex align-items-center justify-content-center">
                    <br /><br />
                    <span>You're not authorized to access this page.</span>
                </div>
                )
        }
    }
}
export default Registration;