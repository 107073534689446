import React from 'react';
import Registration from './Registration';
import Interview from './Interview';
import Header from './Header';

class Start extends React.Component {
    constructor(props) {
        super(props);
        let search = window.location.search;
        let params = new URLSearchParams(search);

        let customerOrderProductId = params.get('t');
        //let tokenUrlSetting = null;
        //let apiUrlSetting = null;
        console.info('NODE_ENV=' + process.env.NODE_ENV);
        switch (window.location.hostname) {
            case "localhost":
                // apiUrlSetting = "https://localhost:44328/api/v3/";
                // tokenUrlSetting = "https://clarigenthealth-dev.auth0.com/oauth/token";
                // apiUrlSetting = "https://platformapi-dev.clarigenthealth.com/api/v3/";
                //tokenUrlSetting = "https://interview-dev.clarigenthealth.com/Session/GetAccessToken";
                break;

            case "registration-dev.clarigenthealth.com":
                // apiUrlSetting = "https://platformapi-dev.clarigenthealth.com/api/v3/";
                // tokenUrlSetting = "https://clarigenthealth-dev.auth0.com/oauth/token";
                break;

            case "registration-test.clarigenthealth.com":
                // apiUrlSetting = "https://platformapi-test.clarigenthealth.com/api/v3/";
                // tokenUrlSetting = "https://clarigenthealth-dev.auth0.com/oauth/token";
                break;

            case "registration-uat.clarigenthealth.com":
                // apiUrlSetting = "https://platformapi-uat.clarigenthealth.com/api/v3/";
                // tokenUrlSetting = "https://clarigenthealth-dev.auth0.com/oauth/token";
                break;
            default:
                // apiUrlSetting = "https://platformapi.clarigenthealth.com/api/v3/";
                // tokenUrlSetting = "https://auth.clarigent.net/oauth/token";
                // tokenUrlSetting = "https://interview.clarigenthealth.com/Session/GetAccessToken";
                break;
        }
        // this.apiUrl = apiUrlSetting;
        // this.tokenUrl = tokenUrlSetting;

        this.state = {
            showEmailForm: true,
            customerEmailAddress: null,
            showRegistrationForm: false,
            showInterviewSession: false,
            existingCustomer: false,
            accessToken: this.accessToken,
            customerOrderProductId: customerOrderProductId,
            productId: null,
            productAvailable: false,
            badData: false,
            apiUrl: process.env.REACT_APP_API_URL
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit = async(event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        this.setState({ customerEmailAddress: data.get('emailAddress') });
        await this.GetAccessToken();

            fetch(process.env.REACT_APP_API_URL + 'subject/registration/status',
            {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    'Authorization': 'Bearer ' + this.accessToken,
                },

                body: JSON.stringify({ 'emailAddress': data.get('emailAddress'), 'customerOrderProductId': this.state.customerOrderProductId }),
            })
            .then(response => {
                if (response.status === 200) {
                    return response.json()
                } else if (response.status === 400)
                {
                    this.setState({ badData: true });
                }
                else {
                    return response.text()
                }
            })
            .then(responseData => {
                if (responseData
                    && responseData.result
                    && responseData.result.emailExists) {
                    if (responseData.result.availableSessions > 0) {
                        //If we have available session for this customer
                        this.setState(
                            {
                                productAvailable: true,
                                subjectId: responseData.result.subjectId,
                                showInterviewSession: true,
                                showEmailForm: false,
                                accessToken: this.accessToken,
                                customerOrderProductId: this.state.customerOrderProductId,
                                apiUrl: this.apiUrl
                            })
                    } else {
                        this.setState({ productAvailable: false, showEmailForm: false })
                    //You do not have any product session available
                    }
                } else {
                    //No existing email
                    this.setState(
                        {
                            showInterviewSession: false,
                            showEmailForm: false,
                            accessToken: this.accessToken,
                            customerOrderProductId: this.state.customerOrderProductId,
                            productAvailable: false,
                            apiUrl: this.apiUrl
                        })
                }
                if (!this.state.badData) {
                    if (responseData.result.minAge > 0 && responseData.result.maxAge > 0) {
                        this.setState({ showRegistrationForm: true, minAge: responseData.result.minAge, maxAge: responseData.result.maxAge })
                    }
                }
            })
            .catch((error) => {
                //console.error(error);
            });
   }
    render() {
        const { customerOrderProductId, customerEmailAddress, accessToken, subjectId, minAge, maxAge, apiUrl } = this.state;
        //No product is available
        if (!this.state.productAvailable && !this.state.showEmailForm && !this.state.showRegistrationForm && !this.state.badData) {
            return (
                <div>
                <Header />
                    <div className="row">
                        <div className="col-md-6">
                            <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                            All the mental health assessments associated with this order have been completed.
                            <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                            <a href="https://www.clarigenthealth.com/contact-us">Buy More link</a>
                            <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                            In the event you completed the Clairity screener, but were disconnected or couldn't connect to your follow-up counseling session, please contact technical support at <a href="mailto:support@clarigenthealth.com">support@clarigenthealth.com</a>.
                        </div>
                    </div>
                </div>
            );
        }
        if (this.state.badData) {
            return (
                <div>
                    <Header />
                    <br /><br />
                    <div className="mb-3 h-100 d-flex align-items-center justify-content-center">
                        <span>You've submitted bad data. Contact your administrator for help.</span>
                    </div>
                </div>
                );
        }
        if (this.state.showEmailForm) {
            return (
                <div>
                    <Header />
                    <br /><br />
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                            Thank you for taking time to talk about your mental health. Please enter the email you would like associated with your Clarigent Mental Health Assessment.</div>
                            <div className="row" style={{ marginTop: 1 + 'em' }}></div>
                            <div className="col-lg-3 text-start">
                            <label htmlFor="exampleFormControlInput1" className="form-label"><strong>Email</strong>:&nbsp;</label>
                            <input type="email" name="emailAddress" id="emailAddress" placeholder="name@example.com" required />
                            <input type="hidden" className="form-control" name="customerOrderProductId" id="customerOrderProductId" defaultValue={this.state.customerOrderProductId} />
                                &nbsp;<button type='submit' className="btn btn-sm pull-right text-white" style={{ background: "#0277bd" }}>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            );
        }

        if (this.state.showRegistrationForm) {
            return (
                <div>
                    <Registration minAge={minAge} maxAge={maxAge} customerEmailAddress={customerEmailAddress} accessToken={accessToken} customerOrderProductId={customerOrderProductId} apiUrl={apiUrl} />
                </div>
            );
        }
        if (this.state.showInterviewSession) {
             return (
                 <div><Interview subjectId={subjectId} accessToken={accessToken} customerOrderProductId={customerOrderProductId} apiUrl={apiUrl} /></div>
             );
        }
    }
    async GetAccessToken()
    {
        await fetch(process.env.REACT_APP_AUTH0_URL,
            {
                //mode: 'no-cors',
                method: 'POST',
                headers: {
                    'Content-type': 'application/json'
                    //'Content-type': 'application/x-www-form-urlencoded'
                },
                // body: bodyString
                //data: 'audience=https://clarigentapp-dev.clarigenthealth.com/api&grant_type=client_credentials&client_idgJOMNLBOx66K1F547sAyjLXLGna4e0hC&client_secretk7itrFjxMdH_seyXxaamwf-94LQyiIHuputvaVJptIyFtc3CzH5Wox2Cl_8LG20h'
                body: JSON.stringify({ 'audience': process.env.REACT_APP_AUTH0_AUDIENCE, 
                'grant_type': 'client_credentials',
                'client_id': process.env.REACT_APP_AUTH0_CLIENT_ID,
                'client_secret': process.env.REACT_APP_AUTH0_CLIENT_SECRET
                // body: JSON.stringify({ 'audience': 'https://clarigentapp-dev.clarigenthealth.com/api', 
                // 'grant_type': 'client_credentials',
                // 'client_id': 'gJOMNLBOx66K1F547sAyjLXLGna4e0hC',
                // 'client_secret': 'k7itrFjxMdH_seyXxaamwf-94LQyiIHuputvaVJptIyFtc3CzH5Wox2Cl_8LG20h'
             })
            })
            //.then(res => console.log(res));
            .then((response) => {
                if (response.status === 200)
                {
                    return response.json();
                }
            })
            .then((response) => {
                this.accessToken = response['access_token'];
            });
    }
}
export default Start;