import { Component } from 'react';
import { Routes, Route } from 'react-router-dom';
import Registration from './components/Registration';
import Start from './components/Start';
import Home from './components/Home';

class App extends Component {
    render() {
        return (
            <div className="App" style={{ marginLeft: 1 + 'em', marginRight: 1 + 'em' }}>
                <Routes>
                    <Route exact path='/' element={< Home />} />
                    <Route exact path='/registration' element={< Registration />} />
                    <Route exact path='/start' element={< Start />} />
                </Routes>
            </div>
        );
    }
}

export default App;