import React from 'react';
class Header extends React.Component {
    render() {
        return (
        <header className="p-1 text-white">
            <div className="page-header col-md-6 blue">
                <img src="images/clarigent_logo.png" alt="Clarigent Health" height="45px" width="200px" />
            </div>
        </header>
        );
    }
}

export default Header;