import React from 'react';

class Footer extends React.Component {
    render() {
        const date = new Date();
        const currentYear = date.getFullYear();
        return (
            <div>
                <div className="row" style={{ marginTop: 10 + 'em' }}></div>
            <footer className="page-footer text-center text-white container-fluid d-flex fixed-bottom">
                <div className="footer-copyright text-center py-2" style={{background: "#0277bd"}}>
                    If you are currently in immediate suicidal crisis or emotional distress, please call 9-8-8.
                    &copy; {currentYear} Clarigent Corporation - All Rights Reserved. <a rel="noreferrer" style={{ color: "white"}} href="https://www.clarigenthealth.com/privacy-notice" target="_blank">Privacy Policy</a>
                </div>
                </footer>
            </div>
                );
    }
}

export default Footer;